var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"store-file-exam-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('h5',{staticClass:"text-h5"},[_vm._v("Importar Examen")]),_c('small',{staticClass:"text-secondary"},[_vm._v("*Solo archivos CSV xlsx xls máximo 35 MB.")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Titulo del examen","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Titulo del examen","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Materia","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Materia","items":_vm.subjects,"error-messages":errors},model:{value:(_vm.subject_id),callback:function ($$v) {_vm.subject_id=$$v},expression:"subject_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Profesor","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Profesor","error-messages":errors,"items":_vm.users},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Archivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"show-size":"","rules":_vm.customRules,"accept":".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel","error":errors.length > 0,"error-messages":errors[0]},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-caption"},[_vm._v("¿Numero de preguntas por nivel?")])]),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('validation-provider',{attrs:{"name":"Nivel bajo","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"dense":"","type":"number","label":"Nivel bajo"},model:{value:(_vm.low),callback:function ($$v) {_vm.low=_vm._n($$v)},expression:"low"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('validation-provider',{attrs:{"name":"Nivel medio","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"type":"number","label":"Nivel medio"},model:{value:(_vm.medium),callback:function ($$v) {_vm.medium=_vm._n($$v)},expression:"medium"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('validation-provider',{attrs:{"name":"Nivel alto","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"type":"number","label":"Nivel alto"},model:{value:(_vm.high),callback:function ($$v) {_vm.high=_vm._n($$v)},expression:"high"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"outlined":"","block":"","large":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v("Cancelar")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","large":"","disabled":invalid,"color":"secondary"},on:{"click":_vm.save}},[_vm._v("Cargar examen")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }